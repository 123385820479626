<footer id="app-footer">
   <div class="px-3">
      <div class="theme-container">
         <!-- <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="content border-lighter py-5">
                <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
                    <h2 class="uppercase">Subscribe our Newsletter</h2>
                    <p class="desc mb-1">Stay up to date with our latest news and properties</p>
                </div>
                <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribeFormSubmit(subscribeForm.value)" class="custom-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
                    <mat-form-field appearance="outline" floatLabel="always" class="subscribe-input" fxFlex>
                        <input matInput autocomplete="off" formControlName="email" placeholder="Your email address...">                      
                    </mat-form-field>
                    <button mat-flat-button type="submit" class="subscribe-btn" color="primary">Subscribe</button>
                </form>
            </div>
        -->
         <div class="py-1 content border-lighter">
            <div fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="space-around">
               <div class="mt-1" fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45">
                  <a class="logo" routerLink="/">
                     <img src="../../../../assets/images/logos/cancian-logo-white-22.png" />
                  </a>
                  <div class="mt-3" fxLayout="column" fxLayoutAlign="start start">
                     <p fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon color="primary">mail_outline</mat-icon>
                        <a href="mailto:contato@cancianimoveis.com" style="color: #8c8c8c; text-decoration: none"
                           class="mx-2">contato@cancianimoveis.com</a>

                     </p>
                     <p fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon color="primary">groups</mat-icon>
                        <a href="https://painel.umentor.com.br/inteligente_novos/?con_cod=uni49574&pla=5"
                           target="_blank" style="color: #8c8c8c; text-decoration: none" class="mx-2">Trabalhe
                           Conosco</a>

                     </p>

                     <div class="mb-1" fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon color="primary">schedule</mat-icon>
                        <div fxLayout="column">
                           <span class="mx-2">Seg - Sex / 08h30 - 18h30 </span>
                           <span class="mx-2">Sábado / 08h30 - 16hs</span>
                        </div>
                     </div>
                  </div>
                  <app-social-icons [iconSize]="'lg'" fxLayout="row" fxLayoutAlign="start center" class="desc">
                  </app-social-icons>
               </div>

               <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5">
                  <div fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                     <mat-icon color="primary">business</mat-icon>
                     <h2 class="uppercase mx-2">Matriz Dores</h2>
                     <img src="../../../../assets/images/e-icon_small.png" width="20" height="20" />
                  </div>
                  <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                     <mat-icon color="primary">location_on</mat-icon>
                     <a class="mx-2" href="https://g.page/CancianImoveis?share" target="_blank"
                        style="text-decoration: none; color: #8c8c8c">Av. Nossa Senhora das Dores, 2347. <br />
                        (ao lado da rede Vivo) <br />
                        Santa Maria, RS
                     </a>
                  </p>
                  <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                     <mat-icon color="primary">call</mat-icon>
                     <span class="mx-2">(55) 3033-7400</span>
                  </p>
               </div>

               <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5">
                  <div fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                     <mat-icon color="primary">business</mat-icon>
                     <h2 class="uppercase mx-2">Agência Centro</h2>
                  </div>
                  <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                     <mat-icon color="primary">location_on</mat-icon>
                     <a class="mx-2" href="https://goo.gl/maps/tK6YrRLoCnB5iPmx7" target="_blank"
                        style="text-decoration: none; color: #8c8c8c">Rua Venâncio Aires, 1349 loja 2, Centro. (esquina
                        Duque de Caxias) <br />
                        Santa Maria, RS</a>
                  </p>
                  <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                     <mat-icon color="primary">call</mat-icon>
                     <span class="mx-2">(55) 3311-6300</span>
                  </p>
               </div>

               <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5">
                  <div fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                     <mat-icon color="primary">business</mat-icon>
                     <h2 class="uppercase mx-2">Agência Camobi</h2>
                     <img src="../../../../assets/images/e-icon_small.png" width="20" height="20" />
                  </div>
                  <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                     <mat-icon color="primary">location_on</mat-icon>
                     <a class="mx-2" href="https://g.page/CancianImoveisCamobi?share" target="_blank"
                        style="text-decoration: none; color: #8c8c8c">Avenida Prefeito Evandro Behr, 7300 (em frente à
                        rótula da fx.velha Camobi) Santa Maria, RS</a>
                  </p>
                  <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                     <mat-icon color="primary">call</mat-icon>
                     <span class="mx-2">(55) 3026-6300</span>
                  </p>
               </div>

               <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5">
                  <div fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                     <mat-icon color="primary">business</mat-icon>
                     <h2 class="uppercase mx-2">Agência Pinheiro Machado</h2>
                     <img src="../../../../assets/images/e-icon_small.png" width="20" height="20" />
                  </div>
                  <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                     <mat-icon color="primary">location_on</mat-icon>
                     <a class="mx-2" href="https://g.page/CancianImoveisCamobi?share" target="_blank"
                        style="text-decoration: none; color: #8c8c8c">
                        Rua Maranhão, 69 (ao lado do Sicredi) Santa Maria, RS </a>
                  </p>
                  <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                     <mat-icon color="primary">call</mat-icon>
                     <span class="mx-2">(55) 3290-6301</span>
                  </p>
               </div>
            </div>

         </div>
         <div fxLayout="column" fxLayoutAlign="space-between center" class="copyright">
            <div class="mt-1 desc" style="text-align: justify">
               Os dados fornecidos podem conter algumas imprecisões, assim como os valores podem ser alterados a
               qualquer momento a critério dos respectivos proprietários.
            </div>
            <div class="mt-1 desc" style="text-align: justify">Cancian Imóveis. CRECI 23.676 J.</div>
         </div>
         <div fxLayout="column" class="copyright" fxLayoutAlign="center center">
            <p class="desc" style="text-align: justify">Copyright © 2023</p>
            <p class="desc" style="text-align: justify">Todos os Direitos Reservados</p>
            <a class="desc" href="/termos-de-uso-e-privacidade"
               style="text-align: justify; text-decoration: underline; color: #ffffffb3">Termos de Uso e
               Privacidade</a>
         </div>
      </div>
   </div>
</footer>